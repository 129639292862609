<template>
  <div>
    <el-select
      :value="value"
      select-label="Leverandør"
      label="full_name"
      :reduce="user => user.id"
      :options="users"
      :disabled="disabled"
      select-name="rolle"
      clearable
      class="flex-1 mb-20"
      @input="$emit('input', $event)"
    />
  </div>
</template>

<script>
const ElSelect = () => import('@/components/shared/ELSelect')

export default {
  name: 'ProductsFilter',

  components: { ElSelect },

  props: {
    users: { type: Array, default: () => [] },
    value: { type: [String, Number], default: '' },
    disabled: { type: Boolean, default: false }
  }
}
</script>
